import InStockIcon from "~/components/ui/icons/in-stock-icon";
import LowStockIcon from "~/components/ui/icons/low-stock-icon";
import OutOfStockIcon from "~/components/ui/icons/out-of-stock-icon";
import { P } from "~/components/ui/text";

type Props = {
  totalProducts: number;
  inStockProducts: number;
  displayMessage?: boolean;
};

const CartClickAndCollectStockStatusMessage = ({
  totalProducts,
  inStockProducts,
  displayMessage = true,
}: Props) => {
  const icon =
    inStockProducts === totalProducts && totalProducts > 0 ? (
      <InStockIcon />
    ) : inStockProducts === 0 ? (
      <OutOfStockIcon />
    ) : (
      <LowStockIcon />
    );
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-1">
        {icon}
        <P>
          {inStockProducts} of {totalProducts} products available
        </P>
      </div>
      {inStockProducts === 0 && displayMessage && (
        <P className="text-xs text-error">
          All of your Items are not in stock at your store. Please try another
          store, or select delivery.
        </P>
      )}
      {totalProducts > inStockProducts &&
        inStockProducts > 0 &&
        displayMessage && (
          <P className="text-xs text-error">
            Sorry, we do not have enough stock of 1 or more items at this store.
            Please remove them, reduce the quantity, or try another store.
          </P>
        )}
    </div>
  );
};

export default CartClickAndCollectStockStatusMessage;
